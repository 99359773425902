import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";

import MainContainer from "../../layout/MainContainer";
import { showError } from "../../constants";
import { useLazyGetCategoryByIdQuery } from "../../services/category";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CategoryDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const UserPlanFeatures = [
    { id: 1, name: "3 times per day" },
    // { id: 2, name: "Genetic Testing" },
    // { id: 3, name: "Allergy Monitoring" },
  ];
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [data, setData] = useState<any>({});
  const [getCategoryMethod, { isLoading }] = useLazyGetCategoryByIdQuery();
  const getCategoryById = async () => {
    try {
      const response = await getCategoryMethod({ id: id }).unwrap();
      if (response.statusCode == 200) {
        console.log(response?.data?.name);
        setData(response?.data);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };
  useEffect(() => {
    if (id) {
      getCategoryById();
    }
  }, []);

  const parseHTML = (htmlString: string | undefined) => {
    if (!htmlString) return "";

    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText || "";
  };
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Category Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-category");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={12} className="view_box_list">
                <Grid item lg={2} md={2} sm={6} xs={12}>
                  <Typography component="h5">Image</Typography>
                  <figure className="profile_img">
                    <img
                      style={{ borderRadius: 0 }}
                      src={
                        data?.image
                          ? data?.image
                          : "/static/images/category.png"
                      }
                      alt=""
                    />
                  </figure>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item mt={3} xs={12} className="view_box_list">
                    {/* <Typography
                      className="detail_title mn_hdng"
                      component="h2"
                      mb={3}
                    >
                      Name
                    </Typography> */}
                    <Grid container spacing={3}>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Category Name</Typography>
                          <Typography component="p">{data?.name}</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            Category Description
                          </Typography>
                          <Typography component="p">
                            {data?.description == "<p></p>\n"
                              ? "No Description"
                              : parseHTML(data?.description)}
                          </Typography>
                        </Box>
                      </Grid>
                      {/* <Grid item xs={12}>
  <Box>
    <Typography component="h5">Description</Typography>
    {UserPlanFeatures.map((feature:any) => (
      <Typography key={feature.id} component="p" sx={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '5px', width: '5px', height: '5px', borderRadius: '50%', backgroundColor: 'black', display: 'inline-block' }}></span>
        {feature.name}
      </Typography>
    ))}
   
  </Box>
</Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default CategoryDetails;
